import React from "react";

const Footer = () => {
	return (
		<div className="footer out-footer">
			
		</div>
	);
};

export default Footer;
